<template>
    <v-dialog v-model="show" max-width="400px">
        <v-card class="rounded_card sky">
            <v-card-title>
                <v-btn color="primary" class="ml-auto" @click="show = false" icon><v-icon>mdi-close</v-icon></v-btn>
            </v-card-title>
            <div class="text-center content-container">
                <span class="success-title">Payment canceled! </span>
                <!-- <p class="subheading">Welcome to Quaro <span class="booked-package">{{booked_package}}</span></p> -->
            </div>
            <v-card-text>
                <div class="text-center img-conainer">
                    <!-- Img rocket -->
                    <img class="rocket" width="180px" :src="rocket03" />
                </div>

                <ul class="star">
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                </ul>
            </v-card-text>
            <!-- <v-card-actions>
          <v-btn block @click="show=false" color="primary" class="mx-auto">Let´s go!</v-btn>
        </v-card-actions> -->
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        name: "CheckoutCanceled",
        props: {},
        data: () => ({
            rocket03: require("../../assets/icons/__red_rocket03.svg"),
            show: true,
            auth: "Basic " + window.btoa(process.env.VUE_APP_AUTH)
        }),
        methods: {
            checkPaymentStatus() {}
        },
        created() {}
    }
</script>
<style lang="scss" scoped>
    .sky {
        background-image: url(../../assets/icons/layers.svg);
        padding-bottom: 55px;
        //  background: linear-gradient(to bottom, #999494 10%, #ffffff 60%);
    }
    .success-title {
        color: black;
        font-size: 2em;
        font-weight: 500;
        z-index: 2;
    }
    .subheading {
        color: rgb(129, 129, 129);
        font-weight: bold;
    }

    .rocket {
        animation: bounce 0.5s infinite;
    }

    .img-conainer {
        /* background-color: beige; */
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        padding-top: 55px;
        padding-bottom: 55px;
        border-radius: 50%;
    }
    .star li {
        list-style: none;
        position: absolute;
        &:before,
        &:after {
            content: "";
            position: absolute;
            background-color: white;
        }
        &:before {
            width: 10px;
            height: 2px;
            border-radius: 50%;
        }
        &:after {
            height: 8px;
            width: 2px;
            left: 4px;
            top: -3px;
        }
        &:first-child {
            top: 17px;
            left: 143px; //check
            animation: twinkle 0.4s infinite;
        }
        &:nth-child(2) {
            top: 38px;
            left: 60px; //check
            animation: twinkle 0.5s infinite;
            &:before {
                height: 1px;
                width: 5px;
            }
            &:after {
                width: 1px;
                height: 5px;
                top: -2px;
                left: 2px;
            }
        }
        &:nth-child(3) {
            left: 280px;
            top: 160px; //Check
            animation: twinkle 1s infinite;
        }
        &:nth-child(4) {
            left: 100px;
            top: 200px; //check
            animation: twinkle 0.5s ease infinite;
        }
        &:nth-child(5) {
            left: 225px;
            top: 55px; //Check
            animation: twinkle 0.4s ease infinite;
        }
        &:nth-child(6) {
            top: 112px;
            left: 72px;
            animation: twinkle 0.2s infinite;
            &:before {
                height: 1px;
                width: 5px;
            }
            &:after {
                width: 1px;
                height: 5px;
                top: -2px;
                left: 2px;
            }
        }
    }

    @keyframes bounce {
        0% {
            transform: translate3d(0px, 0px, 0);
        }
        50% {
            transform: translate3d(0px, -4px, 0);
        }
        100% {
            transform: translate3d(0px, 0px, 0);
        }
    }
    @keyframes twinkle {
        80% {
            transform: scale(1.1);
            opacity: 0.7;
        }
    }
</style>
